<template>
  <div class="test-major">
    <!-- 头部组件 -->
    <!-- <header_public :navindex="6"></header_public> -->

    <!-- <img class="banner" src="../../assets/images/test-pc/bg15.jpg" /> -->
    <div class="banner"></div>
    <div class="leftMenu">
      <div class="list">
        <div @click="advice">课程大纲</div>
        <div @click="advice">薪资待遇</div>
        <div @click="advice">入学课程</div>
        <div @click="advice">就近校区</div>
        <div class="shiting" @click="advice">申请试听</div>
      </div>
      <div class="toTop"></div>
    </div>
    <!-- 第一个板块 -->
    <div class="section">
      <div class="title">软件测试 IT技术风口 职业发展前景广阔</div>
      <div class="line-box">
        <div class="line"></div>
        <div class="icon"></div>
        <div class="line"></div>
      </div>
      <div class="future-box">
        <div @mouseenter="hoverjob(1)" @mouseleave="leavejob(1)" :class="[
          'fb-item',
          jobid == '1' ? 'fb-item_active' : ''
        ]">
          <img class="fbi-icon" src="../../assets/images/test-pc/icon17.png" alt="" />
          <div class="fbi-title">人才缺口大</div>
          <div class="fbi-desc">国内软件测试缺口超30万人，且逐年递增20%</div>
        </div>
        <div @mouseenter="hoverjob(2)" @mouseleave="leavejob(2)" :class="[
          'fb-item',
          jobid == '2' ? 'fb-item_active' : ''
        ]">
          <img class="fbi-icon" src="../../assets/images/test-pc/icon19.png" alt="" />
          <div class="fbi-title">0基础轻松入门</div>
          <div class="fbi-desc">技术门槛低 不会代码也能快速上手</div>
        </div>
        <div @mouseenter="hoverjob(3)" @mouseleave="leavejob(3)" :class="[
          'fb-item',
          jobid == '3' ? 'fb-item_active' : ''
        ]">
          <img class="fbi-icon" src="../../assets/images/test-pc/icon16.png" alt="" />
          <div class="fbi-title">职业寿命长</div>
          <div class="fbi-desc">测试工程师无年龄限制工作时间越长越吃香</div>
        </div>
        <div @mouseenter="hoverjob(4)" @mouseleave="leavejob(4)" :class="[
          'fb-item',
          jobid == '4' ? 'fb-item_active' : ''
        ]">
          <img class="fbi-icon" src="../../assets/images/test-pc/icon14.png" alt="" />
          <div class="fbi-title">高起点好就业</div>
          <div class="fbi-desc">入行门槛低，低学历 也可轻松过万</div>
        </div>
        <div @mouseenter="hoverjob(5)" @mouseleave="leavejob(5)" :class="[
          'fb-item',
          jobid == '5' ? 'fb-item_active' : ''
        ]">
          <img class="fbi-icon" src="../../assets/images/test-pc/icon15.png" alt="" />
          <div class="fbi-title">发展方向广</div>
          <div class="fbi-desc">各行各业都需要 IT、金融、 游戏等行业</div>
        </div>
      </div>
      <div class="future-box-bottom">
        <div class="fbb-left">
          <div class="fbbl-a">
            <div>选软件测试</div>
            <div>做职场全「薪」人才</div>
          </div>
          <div class="fbbl-b">
            <img class="bg1" src="../../assets/images/test-pc/bg1.png" />
            <div class="fbblb-title">软件测试火箭班学员</div>
            <div class="fbblb-desc">毕业可达中级测试工程师</div>
            <div class="fbblb-desc">拥有2-3年测试经验</div>
          </div>
        </div>
        <div class="fbb-right">
          <div class="fbbr-top">
            <img class="icon29" src="../../assets/images/test-pc/icon29.png" />
            <div class="fbbr-item color-green-active">
              <div>技术方向</div>
            </div>
            <img class="fbbr-icon" src="../../assets/images/test-pc/icon28.png" />
            <div class="fbbr-item">
              <div>功能测试</div>
              <div>接口测试</div>
            </div>
            <img class="fbbr-icon" src="../../assets/images/test-pc/icon26.png" />
            <div class="fbbr-item">
              <div>性能测试</div>
              <div>自动化测试</div>
            </div>
            <img class="fbbr-icon" src="../../assets/images/test-pc/icon26.png" />
            <div class="fbbr-item">
              <div>安全性测试</div>
              <div>测试开发</div>
            </div>
          </div>
          <div class="fbbr-bottom">
            <img class="icon30" src="../../assets/images/test-pc/icon30.png" />
            <div class="fbbr-item color-green-active">
              <div>管理方向</div>
            </div>
            <img class="fbbr-icon" src="../../assets/images/test-pc/icon28.png" />
            <div class="fbbr-item">
              <div>测试组长</div>
            </div>
            <img class="fbbr-icon" src="../../assets/images/test-pc/icon26.png" />
            <div class="fbbr-item">
              <div>测试经理</div>
            </div>
            <img class="fbbr-icon" src="../../assets/images/test-pc/icon26.png" />
            <div class="fbbr-item">
              <div>测试总监</div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-advice-box">
        <div class="btn-advice" @click="advice">点击测评个人发展路线</div>
      </div>
    </div>

    <!-- 第二个板块 -->
    <div class="section bg-white">
      <div class="title">五大技术课程 实战演练贯穿始终</div>
      <div class="line-box">
        <div class="line"></div>
        <div class="icon"></div>
        <div class="line"></div>
      </div>
      <div class="s2-swiper">
        <div class="s2-mask"></div>
        <div class="s2-swipter-item-box">
          <img class="s2-swiper-item" :src="course[prevCourse]" alt="轮播图背景" />
          <img class="s2-swiper-item" :src="course[nextCourse]" alt="轮播图背景" />
        </div>
        <img class="s2-swiper-current" :src="course[currentCourse]" alt="轮播图背景" />
      </div>
      <div class="touying">
        <div class="tcontent">

        </div>
        <div class="s2-dot-box" style="margin-top: 0vw;">
          <div :class="{ 's2-dot': true, 's2-dot-current': currentCourse == index }" v-for="(item, index) in course"
            :key="index"></div>
        </div>
        <div class="btn-advice-box">
          <div class="btn-advice" @click="advice">了解更多技术内容</div>
        </div>

      </div>
    </div>

    <!-- 第三个板块 -->
    <div class="section bg-blue">
      <div class="title color-white">拥有好技术 成就好未来</div>
      <div class="line-box">
        <div class="line line-blue"></div>
        <div class="icon icon43"></div>
        <div class="line line-blue"></div>
      </div>
      <div class="section3-item-box">
        <!-- <div @mouseenter="hoverxinzi(1)" @mouseleave="leavexinzi(1)" :class="[
            's3ib-item',
            xinziid == '1' ? 's3ib-item_active' : ''
          ]">
          <div class="s3ibi-title">2018年学员</div>
          <div class="s3ibi-body">
            <div class="s3ibib-title">平均就业薪资</div>
            <div class="s3ibib-money">
              <div>¥</div>
              <div class="money">12033</div>
              <div>/月</div>
            </div>
            <div class="hightest-money-title">最高就业薪资</div>
            <div class="hightest-money color-white bg-blue-linear">
              <div>¥</div>
              <div class="money color-white">2****</div>
              <div>/月</div>
            </div>
          </div>
        </div> -->
        <div @mouseenter="hoverxinzi(1)" @mouseleave="leavexinzi(1)" :class="[
          's3ib-item',
          xinziid == '1' ? 's3ib-item_active' : ''
        ]">
          <div class="s3ibi-title">2019年学员</div>
          <div class="s3ibi-body">
            <div class="s3ibib-title">平均就业薪资</div>
            <div class="s3ibib-money">
              <div>¥</div>
              <div class="money">12531</div>
              <div>/月</div>
            </div>
            <div class="hightest-money-title">最高就业薪资</div>
            <div class="hightest-money color-white bg-blue-linear">
              <div>¥</div>
              <div class="money color-white">24000</div>
              <div>/月</div>
            </div>
          </div>
        </div>
        <div @mouseenter="hoverxinzi(2)" @mouseleave="leavexinzi(2)" :class="[
          's3ib-item',
          xinziid == '2' ? 's3ib-item_active' : ''
        ]">
          <div class="s3ibi-title">2020年学员</div>
          <div class="s3ibi-body">
            <div class="s3ibib-title">平均就业薪资</div>
            <div class="s3ibib-money">
              <div>¥</div>
              <div class="money">13189</div>
              <div>/月</div>
            </div>
            <div class="hightest-money-title">最高就业薪资</div>
            <div class="hightest-money color-white bg-blue-linear">
              <div>¥</div>
              <div class="money color-white">25000</div>
              <div>/月</div>
            </div>
          </div>
        </div>
        <div @mouseenter="hoverxinzi(3)" @mouseleave="leavexinzi(3)" :class="[
          's3ib-item',
          xinziid == '3' ? 's3ib-item_active' : ''
        ]">
          <div class="s3ibi-title">2021年学员</div>
          <div class="s3ibi-body">
            <div class="s3ibib-title">平均就业薪资</div>
            <div class="s3ibib-money">
              <div>¥</div>
              <div class="money">1****</div>
              <div>/月</div>
            </div>
            <div class="hightest-money-title">最高就业薪资</div>
            <div class="hightest-money color-white bg-blue-linear">
              <div>¥</div>
              <div class="money color-white">22000</div>
              <div>/月</div>
            </div>
          </div>
        </div>
        <div @mouseenter="hoverxinzi(4)" @mouseleave="leavexinzi(4)" :class="[
          's3ib-item',
          xinziid == '4' ? 's3ib-item_active' : ''
        ]">
          <div class="s3ibi-title">2022年学员</div>
          <div class="s3ibi-body">
            <div class="s3ibib-title">平均就业薪资</div>
            <div class="s3ibib-money">
              <div>¥</div>
              <div class="money">1****</div>
              <div>/月</div>
            </div>
            <div class="hightest-money-title">最高就业薪资</div>
            <div class="hightest-money color-white bg-blue-linear">
              <div>¥</div>
              <div class="money color-white">25000</div>
              <div>/月</div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-advice-box">
        <div class="btn-advice" @click="advice">如何拿到高薪</div>
      </div>
    </div>

    <!-- 第四个板块 -->
    <div class="section">
      <div class="title">
        <span>软件测试火箭班</span>
        <span class="color-green">火热报名中</span>
      </div>
      <div class="line-box">
        <div class="line"></div>
        <div class="icon"></div>
        <div class="line"></div>
      </div>
      <div class="s4-form-box">
        <div class="s4-form">
          <div class="s4f-left">
            <div class="s4fl-title">
              <img class="s4-icon" src="../../assets/images/test-pc/icon22.png" alt="图标" />
              <div>请选择您的学历</div>
            </div>
            <div class="s4-item-box">
              <div :class="{
                's4-item': true,
                's4-item-active': index == educationIndex,
              }" v-for="(item, index) in education" :key="index" @click="educationChange(index)">
                {{ item }}
              </div>
            </div>
            <div class="s4fl-title">
              <img class="s4-icon" src="../../assets/images/test-pc/icon22.png" alt="图标" />
              <div>请选择您的状态</div>
            </div>
            <div class="s4-item-box">
              <div :class="{
                's4-item w73': true,
                's4-item-active': index == ageIndex,
              }" v-for="(item, index) in age" :key="index" @click="ageChange(index)">
                {{ item }}
              </div>
            </div>
            <div class="s4-input-section">
              <div class="s4-input-box">
                <img class="input-icon" src="../../assets/images/test-pc/icon48.png" alt="图标" />
                <input class="s4-input" type="text" placeholder="请输入手机号" v-model="tel" />
              </div>
              <div class="s4-input-box">
                <img class="input-icon" src="../../assets/images/test-pc/icon47.png" alt="图标" />
                <input class="s4-input" type="text" placeholder="请输入验证码" v-model="telCode" />
                <div class="tel-code" @click="getcode">{{ codeTip }}</div>
              </div>
            </div>
            <div class="s4-btn-box">
              <div class="s4-btn-submit" @click="submitStudent">
                抢占学费优惠名额
              </div>
            </div>
          </div>
          <div class="s4f-right"></div>
        </div>
        <div class="tag-blue"></div>
      </div>
    </div>

    <!-- 第五个板块 -->
    <div class="section bg-white">
      <div class="title">渐进式课程内容 对标企业岗位需求</div>
      <div class="line-box">
        <div class="line"></div>
        <div class="icon"></div>
        <div class="line"></div>
      </div>
      <div class="course-a">
        <img class="bg23" src="../../assets/images/test-pc/bg23.png" alt="薪资图谱" />
      </div>
      <div class="course-b">
        <div class="cb-head">
          <div class="cbh-left">
            <img class="icon24" src="../../assets/images/test-pc/icon24.png" alt="图标" />
            <span>软件测试技术</span>
          </div>
          <img class="icon18" src="../../assets/images/test-pc/icon18.png" alt="图标" />
        </div>
        <div class="cb-title">
          <div class="cbt-left">P0: 7天 0元试学 开启高薪旅程</div>
          <div class="cbt-right">计算机基础、HTML常用标签、css</div>
        </div>
        <div class="cb-table-head">
          <div>课程学习内容</div>
          <div>阶段方向</div>
        </div>
        <div class="table-body">
          <div class="tb-item">
            <div class="tb-item-left">阶段：P1</div>
            <div class="tb-item-middle">
              软件开发理论和背景、软件测试理论、测试用例、缺陷报告,测试、报告和禅道、MySQL、MySQL多表联查和子查询、Linux测试、环境部署和项目搭建、
              Shell、网络协议,接口测试,接口相关文档、
              postman使用、charles、Fiddler工具使用
            </div>
            <div class="tb-item-right">
              <div class="tbir-list">
                <div class="circle"></div>
                <div>学时：一个月</div>
              </div>
              <div class="tbir-list">
                <div class="circle"></div>
                <div>就业方向：功能测试测试工程师 接口测试测试工程师</div>
              </div>
              <div class="tbir-list">
                <div class="circle"></div>
                <div>胜任薪资：8000元-12000元</div>
              </div>
            </div>
          </div>
          <div class="tb-item">
            <div class="tb-item-left">阶段：P2</div>
            <div class="tb-item-middle">
              python基本语法介绍、python 语句、python的字符串和列表、
              python的元组和列表集合和函数、文件操作和异常处理、
              python面向对象、unittest单元测试、pytest单元测试、
              requests接口测试、web端自动化基础、web定位元素和脚本、
              po模型、add命令、Monkey测试和云测平台、app端自动化基
              础、App端自动化测试高级、Jenkins集成自动化
            </div>
            <div class="tb-item-right">
              <div class="tbir-list">
                <div class="circle"></div>
                <div>学时：一个月</div>
              </div>
              <div class="tbir-list">
                <div class="circle"></div>
                <div>就业方向：性能测试测试工程师 自动化测试工程师</div>
              </div>
              <div class="tbir-list">
                <div class="circle"></div>
                <div>胜任薪资：12000元-15000元</div>
              </div>
            </div>
          </div>
          <div class="tb-item">
            <div class="tb-item-left">阶段：P3</div>
            <div class="tb-item-middle">
              性能测试基础、jmeter脚本开发、Jmeter业务逻辑与控制、
              jmeter脚本开发进阶Dubbo脚本开发、jmeter负载与监听、
              系统资源监控、系统调优、Sonar代码质量管理、性能测试集成
              与报告、locust性能测试工具、微服务负载与微服务、flask、 vue
            </div>
            <div class="tb-item-right">
              <div class="tbir-list">
                <div class="circle"></div>
                <div>学时：一个月</div>
              </div>
              <div class="tbir-list">
                <div class="circle"></div>
                <div>就业方向：性能测试测试工程师 测试平台开发工程师</div>
              </div>
              <div class="tbir-list">
                <div class="circle"></div>
                <div>胜任薪资：15000元-20000元</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-advice-box">
        <div class="btn-advice" @click="advice">了解更多课程内容</div>
      </div>
    </div>

    <!-- 第六个板块 -->
    <div class="section bg-blue">
      <div class="title color-white">20+主流测试工具 快速提升工作效率</div>
      <div class="line-box">
        <div class="line line-blue"></div>
        <div class="icon icon43"></div>
        <div class="line line-blue"></div>
      </div>
      <div class="s6-body">
        <div class="s6b-item-box">
          <div class="s6b-item">
            <div class="s6bi-left">基础工具</div>
            <div class="s6bi-right">
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/icon23.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/bg5.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/icon5.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech icon-add" src="../../assets/images/test-pc/icon25.png" />
              </div>
            </div>
          </div>
          <div class="s6b-item">
            <div class="s6bi-left">
              <div>测试环境</div>
              <div>管理工具</div>
            </div>
            <div class="s6bi-right">
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/bg8.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/icon13.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/icon10.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech icon-add" src="../../assets/images/test-pc/icon25.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="s6b-item-box">
          <div class="s6b-item">
            <div class="s6bi-left">UI自动化</div>
            <div class="s6bi-right">
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/bg27.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/icon4.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/icon6.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech icon-add" src="../../assets/images/test-pc/icon25.png" />
              </div>
            </div>
          </div>
          <div class="s6b-item">
            <div class="s6bi-left">
              <div>API自动化</div>
            </div>
            <div class="s6bi-right">
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/bg29.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/icon1.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/icon7.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech icon-add" src="../../assets/images/test-pc/icon25.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="s6b-item-box">
          <div class="s6b-item">
            <div class="s6bi-left">APP自动化</div>
            <div class="s6bi-right">
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/icon11.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/bg2.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/icon8.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech icon-add" src="../../assets/images/test-pc/icon25.png" />
              </div>
            </div>
          </div>
          <div class="s6b-item">
            <div class="s6bi-left">
              <div>自动化</div>
              <div>测试框架</div>
            </div>
            <div class="s6bi-right">
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/bg6.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/bg7.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/bg3.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech icon-add" src="../../assets/images/test-pc/icon25.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="s6b-item-box">
          <div class="s6b-item">
            <div class="s6bi-left">性能测试</div>
            <div class="s6bi-right">
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/bg28.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/icon2.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/icon9.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech icon-add" src="../../assets/images/test-pc/icon25.png" />
              </div>
            </div>
          </div>
          <div class="s6b-item">
            <div class="s6bi-left">
              <div>项目管理</div>
              <div>CI/CD</div>
            </div>
            <div class="s6bi-right">
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/bg4.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/icon3.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech" src="../../assets/images/test-pc/icon12.png" />
              </div>
              <div class="s6bir-item">
                <img class="icon-tech icon-add" src="../../assets/images/test-pc/icon25.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-advice-box">
        <div class="btn-advice" @click="advice">了解更多测试工具</div>
      </div>
    </div>

    <!-- 第七个板块 -->
    <div class="section">
      <div class="title">严选优秀师资 学习就业更轻松</div>
      <div class="line-box">
        <div class="line"></div>
        <div class="icon"></div>
        <div class="line"></div>
      </div>
      <div class="s7-head">
        <div class="s7h-item">
          <img class="icon-s7" src="../../assets/images/test-pc/icon38.png" alt="图标" />
          <div class="s7hi-right">
            <div class="s7hir-title">源头把控</div>
            <div class="s7hir-desc">多年测试经验的职场精英</div>
          </div>
        </div>
        <div class="s7h-item">
          <img class="icon-s7" src="../../assets/images/test-pc/icon39.png" alt="图标" />
          <div class="s7hi-right">
            <div class="s7hir-title">层层筛选</div>
            <div class="s7hir-desc">全国面试，百里挑一</div>
          </div>
        </div>
        <div class="s7h-item">
          <img class="icon-s7" src="../../assets/images/test-pc/icon40.png" alt="图标" />
          <div class="s7hi-right">
            <div class="s7hir-title">定期培训</div>
            <div class="s7hir-desc">岗前/岗后培训，练课</div>
          </div>
        </div>
        <div class="s7h-item">
          <img class="icon-s7" src="../../assets/images/test-pc/icon41.png" alt="图标" />
          <div class="s7hi-right">
            <div class="s7hir-title">阶段考核</div>
            <div class="s7hir-desc">六大考核标准，教学护航</div>
          </div>
        </div>
      </div>
      <div class="s7-body">
        <div class="s7b-head">
          <div class="s7bh-title">{{ avatar[currentAvatar].title }}</div>
          <div class="s7bh-line"></div>
          <div class="s7bh-position">{{ avatar[currentAvatar].position }}</div>
        </div>
        <div class="s7b-body">
          <div>经验：{{ avatar[currentAvatar].draft }}</div>
          <div>履历：{{ avatar[currentAvatar].resume }}</div>
          <div>成就：{{ avatar[currentAvatar].achieve }}</div>
          <div class="avatar-box">
            <!--               @click="avatarChange(index)" -->
            <div class="avatar-mini-box" v-for="(item, index) in avatar" :key="index" @mouseenter="avatarChange(index)">
              <!-- <img class="avatar-mini" :src="item.miniUrl" alt="头像" /> -->
              <div class="avatar-mini" :style="{
                'background-image':
                  'url(' +
                  (item.miniUrl) +
                  ')',
              }"></div>
              <div class="avatar-mini-mask" v-if="currentAvatar != index"></div>
            </div>
          </div>
          <div class="avatar-preview">
            <img class="avatar-big" :src="avatar[currentAvatar].previewUrl" alt="头像预览" />
          </div>
        </div>
      </div>
      <div class="btn-advice-box">
        <div class="btn-advice" @click="advice">了解更多讲师信息</div>
      </div>
    </div>

    <!-- 第八个板块 -->
    <div class="section bg-white">
      <div class="title">全方位就业服务 让你就业不迷茫</div>
      <div class="line-box">
        <div class="line"></div>
        <div class="icon"></div>
        <div class="line"></div>
      </div>
      <div class="s8-item-box m34">
        <div class="s8-item" @mouseenter="schoolMouseover(0)" @mouseleave="schoolMouseout(0)">
          <div class="s8i-top">
            <div class="s8i-top-mask"></div>
            <img class="s8i-top-bg" src="../../assets/images/test-pc/bg9.png" alt="背景" />
            <div class="s8i-top-desc">
              <img class="s8i-top-icon" style="z-index: 1;" src="../../assets/images/test-pc/icon20.png" alt="图标" />
              <img style="z-index: 0;" class="s8i-top-icon s8i-top-icons" src="../../assets/images/test-pc/面试指导.png"
                alt="图标" />
              <div class="titles">面试指导</div>
              <div class="arrow-line"></div>
            </div>
          </div>
          <div class="s8i-bottom">
            入学签订就业服务协议；一地学习全国就业
          </div>
        </div>
        <div class="s8-item">
          <div class="s8i-top">
            <div class="s8i-top-mask"></div>
            <img class="s8i-top-bg" src="../../assets/images/test-pc/bg10.png" alt="背景" />
            <div class="s8i-top-desc">
              <img style="z-index: 1;" class="s8i-top-icon" src="../../assets/images/test-pc/icon45.png" alt="图标" />
              <img style="z-index: 0;" class="s8i-top-icon s8i-top-icons" src="../../assets/images/test-pc/面试资料.png"
                alt="图标" />
              <div class="titles">面试资料</div>
              <div class="arrow-line"></div>
            </div>
          </div>
          <div class="s8i-bottom">
            800+份参考简历 2000+大厂面试题库 300+真实面试录音
          </div>
        </div>
        <div class="s8-item">
          <div class="s8i-top">
            <div class="s8i-top-mask"></div>
            <img class="s8i-top-bg" src="../../assets/images/test-pc/bg11.png" alt="背景" />
            <div class="s8i-top-desc">
              <img style="z-index: 1;" class="s8i-top-icon" src="../../assets/images/test-pc/icon34.png" alt="图标" />
              <img style="z-index: 0;" class="s8i-top-icon s8i-top-icons" src="../../assets/images/test-pc/就业渠道.png"
                alt="图标" />
              <div class="titles">就业渠道</div>
              <div class="arrow-line"></div>
            </div>
          </div>
          <div class="s8i-bottom">
            入学签订就业服务协议；一地学习全国就业万家名企推荐就业入校直招
          </div>
        </div>
      </div>
      <div class="s8-item-box">
        <div class="s8-item">
          <div class="s8i-top">
            <div class="s8i-top-mask"></div>
            <img class="s8i-top-bg" src="../../assets/images/test-pc/bg12.png" alt="背景" />
            <div class="s8i-top-desc">
              <img style="z-index: 1;" class="s8i-top-icon" src="../../assets/images/test-pc/icon35.png" alt="图标" />
              <img style="z-index: 0;" class="s8i-top-icon s8i-top-icons" src="../../assets/images/test-pc/就业服务.png"
                alt="图标" />
              <div class="titles">就业服务</div>
              <div class="arrow-line"></div>
            </div>
          </div>
          <div class="s8i-bottom">
            6个月就业跟进；在职期间24小时随时技术解答
          </div>
        </div>
        <div class="s8-item">
          <div class="s8i-top">
            <div class="s8i-top-mask"></div>
            <img class="s8i-top-bg" src="../../assets/images/test-pc/bg13.png" alt="背景" />
            <div class="s8i-top-desc">
              <img style="z-index: 1;" class="s8i-top-icon" src="../../assets/images/test-pc/icon36.png" alt="图标" />
              <img style="z-index: 0;" class="s8i-top-icon s8i-top-icons" src="../../assets/images/test-pc/就业复盘.png"
                alt="图标" />
              <div class="titles">就业复盘</div>
              <div class="arrow-line"></div>
            </div>
          </div>
          <div class="s8i-bottom">
            面试跟踪；听取面试录音；提出面试建议整理面试问题；监督面试
          </div>
        </div>
        <div class="s8-item">
          <div class="s8i-top">
            <div class="s8i-top-mask"></div>
            <img class="s8i-top-bg" src="../../assets/images/test-pc/bg14.png" alt="背景" />
            <div class="s8i-top-desc">
              <img style="z-index: 1;" class="s8i-top-icon" src="../../assets/images/test-pc/icon37.png" alt="图标" />
              <img style="z-index: 0;" class="s8i-top-icon s8i-top-icons" src="../../assets/images/test-pc/升职加薪.png"
                alt="图标" />
              <div class="titles">升职加薪</div>
              <div class="arrow-line"></div>
            </div>
          </div>
          <div class="s8i-bottom">
            可以免费重修一次；免费享受最新技术栈迭代
          </div>
        </div>
      </div>
      <div class="btn-advice-box">
        <div class="btn-advice" @click="advice">了解更多就业服务</div>
      </div>
    </div>

    <!-- 第九个板块 -->
    <div class="section bg-blue">
      <div class="title color-white">舒适的教学环境 打造良好学习体验</div>
      <div class="line-box">
        <div class="line line-blue"></div>
        <div class="icon icon43"></div>
        <div class="line line-blue"></div>
      </div>
      <div class="s9-box">
        <div class="school-box school">
          <div class="s9-mark-left"></div>
          <div class="s9-mark-right"></div>
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in school" :key="index">
              <!-- 
                  @mouseover="schoolMouseover(index)"
                  @mouseout="schoolMouseout(index)"  -->
              <div class="school-item-box">
                <img class="school-item" :src="item.url" @mouseenter="schoolMouseover(index)"
                  @mouseleave="schoolMouseout(index)" />
                <!-- v-if="item.show" -->
                <div class="school-item-desc">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-advice-box">
        <div class="btn-advice" @click="advice">了解更多校区信息</div>
      </div>
    </div>

    <!-- 第十个板块 -->
    <div class="section">
      <div class="title">你最关心的热门问题</div>
      <div class="line-box">
        <div class="line"></div>
        <div class="icon"></div>
        <div class="line"></div>
      </div>
      <div class="question-list">
        <div class="ql-item-box">
          <div class="ql-item">
            <div class="qlib-left">
              <div class="quara-green"></div>
              <div>学费是多少？</div>
            </div>
            <div class="qlib-right" @click="advice">在线咨询</div>
          </div>
          <div class="ql-item">
            <div class="qlib-left">
              <div class="fire"></div>
              <div class="color-fire">小白多久才能上手？</div>
              <div class="hot-text"></div>
            </div>
            <div class="qlib-right" @click="advice">在线咨询</div>
          </div>
        </div>
        <div class="ql-item-box">
          <div class="ql-item">
            <div class="qlib-left">
              <div class="quara-green"></div>
              <div>我是文科能不能学？</div>
            </div>
            <div class="qlib-right" @click="advice">在线咨询</div>
          </div>
          <div class="ql-item">
            <div class="qlib-left">
              <div class="quara-green"></div>
              <div class="color-fire">学什么程度能找到工作？</div>
            </div>
            <div class="qlib-right" @click="advice">在线咨询</div>
          </div>
        </div>
        <div class="ql-item-box">
          <div class="ql-item">
            <div class="qlib-left">
              <div class="fire"></div>
              <div>学校在哪里？吃饭住宿怎么安排？</div>
              <div class="hot-text"></div>
            </div>
            <div class="qlib-right" @click="advice">在线咨询</div>
          </div>
          <div class="ql-item">
            <div class="qlib-left">
              <div class="quara-green"></div>
              <div class="color-fire">做测试能升到管理层吗？</div>
            </div>
            <div class="qlib-right" @click="advice">在线咨询</div>
          </div>
        </div>
        <div class="ql-item-box">
          <div class="ql-item">
            <div class="qlib-left">
              <div class="quara-green"></div>
              <div>试听在网上还是校区？</div>
            </div>
            <div class="qlib-right" @click="advice">在线咨询</div>
          </div>
          <div class="ql-item">
            <div class="qlib-left">
              <div class="fire"></div>
              <div class="color-fire">软件测试前景怎么样？</div>
              <div class="hot-text"></div>
            </div>
            <div class="qlib-right" @click="advice">在线咨询</div>
          </div>
        </div>
        <div class="ql-item-box">
          <div class="ql-item">
            <div class="qlib-left">
              <div class="quara-green"></div>
              <div>校区开班时间？</div>
            </div>
            <div class="qlib-right" @click="advice">在线咨询</div>
          </div>
          <div class="ql-item">
            <div class="qlib-left">
              <div class="quara-green"></div>
              <div class="color-fire">软件测试必备技能有哪些？</div>
            </div>
            <div class="qlib-right" @click="advice">在线咨询</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <!-- <bottom_public></bottom_public> -->
  </div>
</template>

<script>
import bottom_public from "../../components/bottom_public/bottom_public";
import header_public from "../../components/header_public/header_public";
import Swiper from "swiper/js/swiper.min.js";
import "swiper/css/swiper.min.css";
const axios = require("axios").default;
const telReg = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/; // 手机号验证

export default {
  metaInfo: {
    title: "软件测试培训,零基础软件测试培训班,山西软件测试培训机构-[积云教育]",
    meta: [
      {
        name: "description",
        content:
          "来积云学软件测试,3个月变身软件测试工程师!山西积云软件测试培训机构,汇聚软件测试行业实战大咖,融入企业软件测试真实10+实战项目,还原企业业务场景,学软件测试就来积云教育机构。",
      },
      {
        name: "keywords",
        content:
          "软件测试工程师,软件测试培训多少钱,软件测试培训课,软件测试培训班",
      },
    ],
  },
  created() { },
  data() {
    return {
      jobid: 1,
      xinziid: 1,
      avatar: [
        {
          miniUrl: require("../../assets/images/test-pc/pan-mini.png"),
          previewUrl: require("../../assets/images/test-pc/pan.png"),
          title: "樊庆晓",
          position: "软件测试方向主任",
          draft: "十年测试行业经验",
          resume:
            "曾任职与国家电网金融事业部、中国移动5G事业部、CIMC中集等上市企业，担任过技术总监、测试架构师、系统架构师等职务",
          achieve:
            "曾主导招商证券、恒丰银行短信平台、上海移动CRM系统、企业ERP系统、中国证监会财务报表AI系统分析测试等项目，有着丰富的软件测试、开发、架构经验",
        },
        {
          miniUrl: require("../../assets/images/test-pc/sun-mini.png"),
          previewUrl: require("../../assets/images/test-pc/sun.png"),
          title: "孙鲁燕",
          position: "测试方向副主任",
          draft: "8年职业教育培训行业经验",
          resume:
            "曾任职八维、商鲲、千峰等知名机构进行学管和就业指导工作，担任学管主 任、就业中心主任、教学副总监等职务",
          achieve:
            "在学管期间得到学院、老师、学生一致好评，在就业服务中心工作中 进行就业渠道开发，签署就业企业，促成校企合作使学生的就业率达到95%以上",
        },
        {
          miniUrl: require("../../assets/images/test-pc/wan-mini.png"),
          previewUrl: require("../../assets/images/test-pc/wan.png"),
          title: "安万飞",
          position: "测试实战讲师",
          draft: "10年互联网开发、测试、管理经验",
          resume:
            "曾就职于中国公安部第一研究所、微软软件事业部、人工智能机器人公司等，任职曾开发总监、全栈测试工程师",
          achieve:
            "曾参与网证CTID的SDK开发与测试，与华为、小米、支付宝、微信有深度合作",
        },
        {
          miniUrl: require("../../assets/images/test-pc/liu-mini.png"),
          previewUrl: require("../../assets/images/test-pc/liu.png"),
          title: "刘良誉",
          position: "测试实战讲师",
          draft: "8年工作经验",
          resume: "毕业于郑州大学，曾任职与中国华夏和中信银行软件事业部",
          achieve:
            "曾在公司组建中信银行自动化测试平台，基金估值系统，基金核算系统，庭审核查系统以及RPA自动化系统等项目，有着资深的项目管理经验",
        },
        {
          miniUrl: require("../../assets/images/test-pc/sunw-mini.png"),
          previewUrl: require("../../assets/images/test-pc/sunw.jpg"),
          title: "孙文龙",
          position: "测试实战讲师",
          draft: "12年测试测开经验",
          resume:
            "曾就任某美股上市公司测试主管，外企区块链行业公司技术总监，香港中华煤气有限公司名气通电讯测试部分负责人。任职联想、天融信等多家上市企业",
          achieve:
            "曾在多家上市企业进行测试架构布局，测试平台开发，技术支撑。涉及网络安全、电商、区块链、金融等",
        },
        {
          miniUrl: require("../../assets/images/test-pc/du-mini.png"),
          previewUrl: require("../../assets/images/test-pc/du.jpg"),
          title: "杜从浩",
          position: "测试实战讲师",
          draft: "从事8年教育培训工作",
          resume: "曾在多家知名培机构在学生管理和就业指导工作，有独特方法",
          achieve:
            "累计授课学生5000+，学生及家长满意度99%以上，就业率达到95%以上",
        },
        {
          miniUrl: require("../../assets/images/test-pc/shi-mini.png"),
          previewUrl: require("../../assets/images/test-pc/shi.jpg"),
          title: "石建威",
          position: "测试实战讲师",
          draft: "8年测试、管理工作经验",
          resume: "曾就职于联想旗下全资子公司阳光雨露",
          achieve:
            "负责互联网公司代驾APP项目测试团队管理工作。曾负责国家重点扶植三农项目“智慧农村电商”的测试管理工作",
        },
        {
          miniUrl: require("../../assets/images/test-pc/xu-mini.png"),
          previewUrl: require("../../assets/images/test-pc/xu.jpg"),
          title: "许腾升",
          position: "测试实战讲师",
          draft: "10年物联网开发、测试、项目管理经验",
          resume:
            "曾就职于OPPO软件研发部、Google软件事业部、小米科技等公司，曾任职开发主程，项目经理，测试总监",
          achieve: "丰富的互联网项目开发、测试及管理经验",
        },
      ],
      currentAvatar: 0,
      education: ["高中", "中专", "大专", "本科及以上"],
      educationIndex: 3,
      // age: ["18周岁以下", "18-25周岁", "25-30周岁", "30周岁+"],
      // ageIndex: 0,
      age: ["在校大学生", "应届毕业生", "在职人员", "待业人员"],
      ageIndex: 0,
      school: [
        {
          name: "教室环境",
          url: require("../../assets/images/test-pc/bg30.png"),
          show: false,
        },
        {
          name: "山西校区",
          url: require("../../assets/images/test-pc/bg31.png"),
          show: false,
        },
        {
          name: "校区大厅",
          url: require("../../assets/images/test-pc/bg32.png"),
          show: false,
        },
        {
          name: "校区服务台",
          url: require("../../assets/images/test-pc/bg33.png"),
          show: false,
        },
      ],
      tel: "",
      telCode: "",
      codeTip: "获取验证码",
      timerRuning: false, //是否倒计时进行中
      course: [
        require("../../assets/images/test-pc/bg16.png"),
        require("../../assets/images/test-pc/bg17.png"),
        require("../../assets/images/test-pc/bg18.png"),
      ],
      currentCourse: 0,
      prevCourse: 2,
      nextCourse: 1,
    };
  },
  methods: {
    advice() {
      window.mantis.requestChat();
    },
    hoverjob(index) {
      console.log('设置为', index)
      this.jobid = index
    },
    leavejob(index) {
      console.log('设置为', this.jobid)
      if (this.jobid > 1) {

        this.jobid = 1
      }
    },
    hoverxinzi(index) {
      this.xinziid = index
    },
    leavexinzi() {
      this.xinziid = 1
    },
    cliclImg(index) {
      console.log(index);
    },
    educationChange(index) {
      this.educationIndex = index;
    },
    ageChange(index) {
      this.ageIndex = index;
    },
    avatarChange(index) {
      clearInterval(this.teacherTimer);
      this.currentAvatar = index;
    },
    getcode() {
      if (this.timerRuning) {
        return;
      }

      if (!telReg.test(this.tel)) {
        alert("请输入正确的手机号");
        return;
      }

      axios
        .get(`/api/audition/sendSms?telephone=${this.tel}`)
        .then((res) => {
          console.log(res);
          if (res.data.code == 1) {
            this.countDown();
          } else {
            alert(res.data.msg);
          }
        })
        .catch((errors) => {
          alert(errors.data.msg);
        });
    },
    countDown() {
      alert("验证码已发送，请耐心等待");
      this.timerRuning = true;
      let count = 60;
      this.codeTip = "60秒后重发";
      this.loadingTime = setInterval(() => {
        if (count == 1) {
          count = 60;
          clearInterval(this.loadingTime);
          this.timerRuning = false;
          this.codeTip = "重新获取";
        } else {
          count--;
          this.codeTip = count + "s后重发";
        }
      }, 1000);
    },
    submitStudent() {
      var url = encodeURIComponent(window.location.href);
      if (localStorage.clickNum == 1) {
        alert("您已提交，请不要重复提交");
        return false;
      }
      // 校验手机号
      if (telReg.test(this.tel) == false) {
        alert("请输入正确的手机号");
        return;
      }
      if (!this.telCode.replace(/\s+/g, "")) {
        alert("请输入验证码");
        return;
      }
      var param = {
        age: this.age[this.ageIndex],
        education: this.education[this.educationIndex],
        source_url: url,
        telephone: this.tel,
        code: this.telCode.replace(/\s+/g, ""),
      };
      console.log(param);
      axios
        .post("/api/audition/apply", param)
        .then((res) => {
          if (res.data.code == 1) {
            console.log(111);
            localStorage.clickNum = "1";
            window.sendPage(this.tel, this.education[this.educationIndex], url);
          } else {
            console.log(222);
            alert(res.data.msg);
          }
        })
        .catch((errors) => {
          alert(errors.data.msg);
        });
    },
    schoolMouseover(index) {
      console.log('进入', index)
      this.school[index].show = true;
    },
    schoolMouseout(index) {
      console.log('离开', index)
      this.school[index].show = false;
    }
  },
  mounted() {
    this.schoolSwiper = new Swiper(".school", {
      slidesPerView: 3.3,
      slidesPerview: 'auto',
      autoplay: {
        delay: 2000,
      },
      on: {
      },
      loop: true,
    });

    this.teacherTimer = setInterval(() => {
      this.currentAvatar += 1;
      if (this.currentAvatar >= this.avatar.length) {
        this.currentAvatar = 0;
      }
    }, 2000);

    this.courseTimer = setInterval(() => {
      this.currentCourse += 1;
      if (this.currentCourse > 2) {
        this.currentCourse = 0;
      }

      if (this.currentCourse == 0) {
        this.prevCourse = 2;
        this.nextCourse = 1;
      }
      if (this.currentCourse == 1) {
        this.prevCourse = 0;
        this.nextCourse = 2;
      }
      if (this.currentCourse == 2) {
        this.prevCourse = 1;
        this.nextCourse = 0;
      }
    }, 2000);
  },
  destoryed() {
    clearInterval(this.teacherTimer);
    clearInterval(this.courseTimer);
    this.schoolSwiper = null;
  },
  components: {
    bottom_public,
    header_public,
  },
};
</script>

<style lang="scss" scoped>
@import url("../../assets/css/test_major.css");

* {
  padding: 0;
  box-sizing: border-box;
}

.banner {
  width: 100%;
  // height: 32.3vw;
  height: 36.5vw;
  display: block;
  background: url(../../assets/images/test-pc/bg15.jpg) no-repeat;
  background-size: 100% 36.5vw;
}

// 第一个板块
.section {
  background: linear-gradient(-10deg, #e3ebff, #ffffff);
  overflow: hidden;
}

.bg-white {
  background: #ffffff;
}

.bg-blue {
  background: linear-gradient(137deg, #2f3d69, #1a223e);
}

.title {
  padding: 3.6vw 0 1.4vw;
  text-align: center;
  font-size: 2.2vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #242f57;
  line-height: 1.3vw;
}

.line-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.line {
  width: 47vw;
  height: 0.1px;
  background-color: #dee7f9;
}

.line-blue {
  background-color: #465a96;
}

.icon {
  width: 1.5vw;
  height: 1.6vw;
  background: url(../../assets/images/test-pc/icon42.png) no-repeat;
  background-size: 100% 100%;
}

.icon43 {
  background: url(../../assets/images/test-pc/icon43.png) no-repeat;
  background-size: 100% 100%;
}

.color-white {
  color: #ffffff;
}

.future-box {
  padding: 2.5vw 18.9vw 1.1vw;
  display: flex;
  justify-content: space-between;
}

.fb-item {
  width: 11.5vw;
  padding: 1.7vw 1.2vw 1.2vw;
  border: 0.1vw solid #e5ebfa;
  box-shadow: 0px 0.8vw 1.4vw 0px rgba(199, 209, 236, 0.5);
  border-radius: 0.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fb-item:hover {
  // background: #ffffff;
}

.fbi-icon {
  width: 2.9vw;
  height: 2.8vw;
}

.fbi-title {
  font-size: 1.1vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #2f3d69;
  line-height: 1.3vw;
  margin: 0.9vw 0;
}

.fbi-desc {
  font-size: 0.8vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #6d758e;
  line-height: 1.1vw;
}

.future-box-bottom {
  display: flex;
  justify-content: space-between;
  padding: 0 18.9vw;
}

.fbb-left {
  display: flex;
  align-items: center;
}

.fbb-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fbbl-a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 11.7vw;
  height: 10.5vw;
  text-align: center;
  background: url(../../assets/images/test-pc/bg21.png) no-repeat;
  background-size: 100% 100%;
  font-size: 1.1vw;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

.fbbl-a>div {
  line-height: 1.6vw;
}

.fbbl-b {
  margin: 0 1.1vw 0 1.4vw;
}

.bg1 {
  width: 6.4vw;
  height: 4.9vw;
}

.fbblb-title,
.fbblb-desc {
  font-size: 0.9vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #2f3d69;
  line-height: 1.2vw;
}

.fbblb-desc {
  font-size: 0.8vw;
  color: #8f96ac;
}

.fbbr-top,
.fbbr-bottom {
  display: flex;
  align-items: center;
}

.fbbr-top {
  margin-bottom: 1.9vw;
}

.icon29,
.icon30 {
  width: 0.9vw;
  height: 1vw;
  margin-right: 1.3vw;
}

.icon29 {
  margin-top: 1.3vw;
}

.icon30 {
  margin-bottom: 1.3vw;
}

.fbbr-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 7.2vw;
  height: 3.4vw;
  background: #ffffff;
  border: 0.1vw solid #d2ddff;
  box-shadow: 0px 0.3vw 0.3vw 0px rgba(199, 209, 236, 0.2);
  border-radius: 0.4vw;
  font-size: 0.9vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #425181;
  line-height: 1.3vw;
}

.fbbr-icon {
  width: 1.2vw;
  height: 0.9vw;
  margin: 0 0.6vw;
}

.color-green-active {
  border: 0.1vw solid #6beabc;
  box-shadow: 0px 0.4vw 1.5vw 0px rgba(35, 171, 146, 0.09);
  font-size: 1.1vw;
  color: #01d2b4;
}

.color-green {
  color: #01d2b4;
}

.btn-advice-box {
  padding: 2.3vw 0 3.8vw 0;
  display: flex;
  justify-content: center;
}

.btn-advice {
  width: 12.6vw;
  height: 2.7vw;
  line-height: 2.7vw;
  text-align: center;
  background: url(../../assets/images/test-pc/bg20.png) no-repeat;
  background-size: 100% 100%;
  font-size: 1.1vw;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0px 0.1vw 0px rgba(255, 89, 7, 0.36);
}

.btn-advice:hover {
  cursor: pointer;
}

// 第二个板块
.s2-swiper {
  margin: 4vw auto 1vw;
  position: relative;
  width: 62.3vw;
  height: 18.9vw;
}

.s2-swipter-item-box {
  width: 62.3vw;
  height: 18.9vw;
  display: flex;
  overflow: hidden;
  border-radius: .5vw;
}

.s2-swiper-item {
  flex: 1;
  height: 100%;
}

.s2-mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 62.3vw;
  height: 18.9vw;
  background: rgba(0, 0, 0, 0.5);
  border-radius: .5vw;
  overflow: hidden;
}

.s2-swiper-current {
  width: 44.9vw;
  height: 21.6vw;
  position: absolute;
  top: -1.8vw;
  left: 8.6vw;
  z-index: 2;
  // box-shadow:0px 20px 0px #F3F6FE;
}

.s2-dot-box {
  display: flex;
  justify-content: center;
}

.s2-dot {
  width: 0.5vw;
  height: 0.5vw;
  background: #9da6bf;
  border-radius: 50%;
  margin-right: 0.7vw;
}

.s2-dot-current {
  width: 3.6vw;
  height: 0.4vw;
  background: #01d2b4;
  border-radius: 0.2vw;
}

// 第三个板块
.section3-item-box {
  display: flex;
  justify-content: center;
  margin-top: 2.7vw;
}

.s3ib-item {
  width: 14.5vw;
  height: 16.3vw;
  margin-right: 1.4vw;
  background: url(../../assets/images/test-pc/bg22.png) no-repeat;
  background-size: 100% 100%;
}

.s3ib-item:last-child {
  margin-right: 0;
}

.s3ibi-title {
  font-size: 1.1vw;
  font-family: Arial;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.1vw;
  padding: 1.1vw 0;
  text-align: center;
}

.s3ibi-body {
  padding: 0 0.7vw;
}

.s3ibib-title {
  font-size: 0.9vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #01d2b4;
  line-height: 1.1vw;
  text-align: center;
  margin: 1.2vw 0 0.6vw 0;
}

.s3ibib-money {
  font-size: 0.9vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #2f3d69;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 1.2vw;
  border-bottom: 0.1vw solid #dae2f9;
}

.hightest-money-title {
  margin: 1.4vw 0 0.8vw 0;
  font-size: 0.9vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #2f3d69;
  line-height: 1.1vw;
}

.hightest-money {
  padding: 0.6vw 0;
  width: 12.5vw;
  text-align: center;
  // background: linear-gradient(18deg, #f7cb6b, #fba980);
  font-size: 0.9vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 auto;
}

.money {
  font-size: 1.8vw;
  line-height: 1;
}

.bg-blue-linear {
  background: linear-gradient(16deg, #84f5de, #56e8f2, #4cdaf8);
}

// 第四个板块
.s4-form-box {
  position: relative;
  margin: 2.5vw auto 3.6vw;
  width: 60.9vw;
  height: 26vw;
}

.s4-form {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0.4vw 0.8vw 2.1vw 0px rgba(66, 81, 129, 0.05);
  border-radius: 0.8vw;
  position: absolute;
  padding: 1.6vw 1.6vw 1.6vw 3.3vw;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}

.tag-blue {
  width: 2.2vw;
  height: 2.8vw;
  background: #0bbbf5;
  border-radius: 0.3vw;
  position: absolute;
  top: 2.4vw;
  left: -0.6vw;
  z-index: 1;
}

.s4fl-title {
  display: flex;
  align-items: center;
  font-size: 1.1vw;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #242f57;
  line-height: 1.1vw;
  margin: 1.5vw 0;
}

.s4-icon {
  width: 1.8vw;
  height: 1.8vw;
  margin-right: 1.4vw;
}

.s4-item-box {
  display: flex;
  justify-content: space-between;
}

.s4-item {
  width: 7.3vw;
  height: 2.2vw;
  line-height: 2.2vw;
  text-align: center;
  background: #f8fafb;
  border: 0.1vw solid #c4d8f5;
  border-radius: 0.2vw;
  font-size: 0.8vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #5c6172;
}

.s4-item-active {
  background: rgba(251, 228, 210, 0.6);
  border: 0.1vw solid #ffa969;
  color: #ff6c13;
}

.s4f-left {
  width: 31.8vw;
}

.s4f-right {
  width: 19.1vw;
  height: 22.8vw;
  background: url(../../assets/images/test-pc/bg24.png) no-repeat;
  background-size: 100% 100%;
}

.w73 {
  width: 7.3vw;
}

.s4-input-section {
  display: flex;
  justify-content: space-between;
  margin: 1vw 0;
}

.s4-input-box {
  width: 15vw;
  height: 2.9vw;
  background: #f8fafb;
  border: 0.1vw solid #c4d8f5;
  border-radius: 0.2vw;
  padding: 1vw;
  display: flex;
  align-items: center;
}

.input-icon {
  width: 1.2vw;
  height: 1.2vw;
  margin-right: 0.6vw;
}

.s4-input {
  font-size: 0.8vw;
  width: 5vw;
  flex: 1;
  border: none;
  outline: none;
  background: none;
}

.tel-code {
  font-size: 0.8vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #5c6172;
}

.s4-btn-box {
  display: flex;
  justify-content: center;
}

.s4-btn-submit {
  width: 12.6vw;
  height: 3.1vw;
  line-height: 3.1vw;
  text-align: center;
  background: url(../../assets/images/test-pc/bg25.png) no-repeat;
  background-size: 100% 100%;
  font-size: 1.1vw;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

// 第五个板块
.course-a {
  width: 62.5vw;
  height: 28.8vw;
  background: #ffffff;
  box-shadow: 0.4vw 0.8vw 2.1vw 0px rgba(66, 81, 129, 0.1);
  border-radius: 0.7vw;
  margin: 2.6vw auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.course-b {
  width: 62.5vw;
  margin: 0 auto;
}

.bg23 {
  width: 52.2vw;
  height: 23.4vw;
}

.cb-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3.1vw;
  background: url(../../assets/images/test-pc/bg26.png) no-repeat;
  background-size: 100% 100%;
  padding: 1vw 1.5vw 0.8vw 4.3vw;
}

.icon24 {
  width: 1.3vw;
  height: 1.3vw;
  margin-right: 1.4vw;
}

.icon18 {
  width: 1.4vw;
  height: 1.4vw;
}

.cbh-left {
  display: flex;
  align-items: center;
  font-size: 1vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #fffffa;
  line-height: 2.8vw;
}

.cb-title {
  width: 62.5vw;
  height: 3.3vw;
  background: #f1f3f5;
  padding-left: 4.4vw;
  display: flex;
  align-items: center;
}

.cbt-left {
  font-size: 0.9vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ff6c13;
  line-height: 2.8vw;
  margin-right: 3.1vw;
}

.cbt-right {
  font-size: 0.9vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #494b53;
  line-height: 2.8vw;
}

.cb-table-head {
  height: 2.9vw;
  background: #ffffff;
  padding: 0 15vw 0 9.3vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #425181;
  line-height: 2.8vw;
}

.table-body {
  background: #fcfcfc;
  box-shadow: 0px 0px 0px 0px rgba(36, 47, 87, 0.07),
    0px 0px 0px 0px rgba(36, 47, 87, 0.07);
  border-radius: 0px 0px 0.4vw 0.4vw;
  border: 0.1vw solid #e8eafe;
}

.tb-item {
  display: flex;
  border-top: 0.1vw solid #e8eafe;
}

.tb-item:first-child {
  border-top: none;
}

.tb-item-left,
.tb-item-middle,
.tb-item-right {
  padding: 1.4vw 1.6vw;
  font-size: 0.7vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #7a8194;
  line-height: 1.1vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tb-item-left:hover,
.tb-item-middle:hover {
  color: #01d2b4;
}

.tb-item-right:hover {
  color: #01d2b4;
}

// .tb-item-right:hover.active{
// 	background-color: transparent;
// 	color: red;
// }
.tb-item-left:hover,
.tb-item-middle:hover {}

.tb-item-right:hover .tbir-list .circle {
  background: #01d2b4;
}

.tb-item-left {
  width: 7.7vw;
  border-right: 0.1vw solid #e8eafe;
}

.tb-item-middle {
  flex: 1;
  border-right: 0.1vw solid #e8eafe;
  text-align: left;
}

.tb-item-right {
  width: 21.5vw;
  flex-direction: column;
  align-items: flex-start;
}

.active {
  background: #f5fffd;
  box-shadow: 0 0 0.2vw #a5fff0;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #01d2b4;
  line-height: 1.1vw;
}

.tbir-list {
  display: flex;
  align-items: center;
}

.circle {
  width: 0.3vw;
  height: 0.3vw;
  background: #d3d3d7;
  border-radius: 50%;
  margin-right: 0.7vw;
}

.circle-active {
  background: #01d2b4;
}

// 第六个板块
.s6-body {
  width: 62.4vw;
  height: 23.3vw;
  background: linear-gradient(-63deg, #2a375f, #2e3c68);
  border-radius: 0.2vw;
  margin: 2.7vw auto 0;
  padding: 1.8vw 1.6vw;
}

.s6b-item-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.4vw;
}

.s6b-item-box:last-child {
  margin-bottom: 0;
}

.s6b-item {
  display: flex;
}

.s6bi-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 0.9vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  width: 7.4vw;
  height: 3.9vw;
  background: linear-gradient(0deg, #00c8ae, #21efcd);
  box-shadow: 0px 0.2vw 0.5vw 0px #344476;
  border-radius: 0.2vw;
  margin-right: 1vw;
}

.s6bi-right {
  padding: 0.8vw 0;
  width: 20.4vw;
  height: 3.9vw;
  background: #ffffff;
  box-shadow: 0px 0.2vw 0.5vw 0px #344476;
  border-radius: 0.2vw;
  display: flex;
  align-items: center;
}

.s6bir-item {
  flex: 1;
  border-right: 0.1vw solid #d9dff1;
}

.icon-tech {
  width: auto;
  height: 2.3vw;
}

.icon-add {
  width: 1.4vw;
  height: 1.4vw;
}

// 第七板块
.s7-head {
  padding: 2.4vw 18.8vw 0;
  display: flex;
  justify-content: space-between;
}

.s7h-item {
  padding: 1.2vw 1.8vw;
  margin-right: 0.4vw;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #f9fbff, #ffffff);
  border: 0.1vw solid #ffffff;
  box-shadow: 0px 0.5vw 0.9vw 0px rgba(154, 172, 216, 0.08);
  border-radius: 0.2vw;
}

.s7h-item:last-child {
  margin-right: 0;
}

.icon-s7 {
  margin-right: 1.3vw;
  width: 1.8vw;
  height: 2vw;
}

.s7hir-title {
  font-size: 1.1vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #2f3d69;
  line-height: 1.3vw;
  text-align: left;
}

.s7hir-desc {
  font-size: 0.8vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #8c94aa;
  line-height: 1.3vw;
}

.s7b-head {
  padding: 4.8vw 0 2vw 43.2vw;
}

.s7bh-title {
  font-size: 1.3vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #2f2f2f;
  line-height: 1.4vw;
  text-align: left;
}

.s7bh-line {
  width: 6vw;
  height: 0.1px;
  background: #ff9a4e;
  margin: 0.8vw 0;
}

.s7bh-position {
  font-size: 1vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ff9a4e;
  line-height: 1.4vw;
  text-align: left;
}

.s7b-body {
  width: 62.4vw;
  height: 12.7vw;
  background: linear-gradient(97deg, #02b497, #06eccb);
  margin: 0 auto 3.6vw;
  padding: 2vw 6.4vw 3.8vw 24.4vw;
  text-align: left;
  font-size: 0.8vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.5vw;
  position: relative;
}

.avatar-box {
  display: flex;
  position: absolute;
  left: 23.1vw;
  bottom: -2vw;
}

.avatar-mini-box {
  width: 3.5vw;
  height: 3.9vw;
  margin-right: 0.9vw;
  position: relative;
}

.avatar-mini-box:last-child {
  margin-right: 0;
}

.avatar-mini {
  width: 3.5vw;
  height: 3.9vw;
  background-size: 3.5vw 3.9vw;
}

.avatar-mini-mask {
  width: 3.5vw;
  height: 3.9vw;
  background: rgba(30, 29, 29, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.avatar-preview {
  width: 19.5vw;
  height: 21.5vw;
  position: absolute;
  top: -7vw;
  left: 2.5vw;
  z-index: 1;
  background: url(../../assets/images/test-pc/bg34.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
}

.avatar-big {
  width: 16.7vw;
  height: 22.9vw;
}

// 第八个板块
.s8-item-box {
  display: flex;
  justify-content: space-between;
  padding: 0 18.8vw;
}

.s8-item {
  width: 20.3vw;
  background: #ffffff;
  border: 1px solid #e3e9fb;
  color: #8c94aa;
}

.s8-item:hover {
  transform: translateY(-0.8vw);
  color: #425181;
  // background: ;
}

.s8-item:hover .s8i-top-mask {
  background: linear-gradient(29deg, rgba(0, 200, 174, 0.58), rgba(81, 255, 226, 0.58));
}

.s8-item:hover .s8i-top-icons {
  z-index: 2 !important;
}

.m34 {
  margin: 3.4vw 0 1vw;
}

.s8i-top {
  position: relative;
  width: 100%;
  height: 10.3vw;
}

.s8i-top-mask {
  width: 100%;
  height: 10.3vw;
  background: linear-gradient(29deg,
      rgba(66, 81, 129, 0.63),
      rgba(47, 61, 105, 0.63));
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.s8i-bottom {
  padding: 0.9vw 2.7vw 1.2vw 2.1vw;
  font-size: 0.8vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 1.1vw;
  text-align: left;
}

.s8i-top-bg {
  width: 100%;
  height: 10.3vw;
}

.s8i-top-desc {
  padding-top: 2.9vw;
  width: 100%;
  height: 10.3vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.1vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.5vw;
}

.s8i-top-desc .titles {
  margin-top: 2.3vw;
}

.s8i-top-icon {
  width: 1.8vw;
  height: 1.5vw;
  margin-bottom: 0.8vw;
  position: absolute;
}

.arrow-line {
  margin-top: 0.8vw;
  width: 1.6vw;
  height: 0.2vw;
  background: #ffffff;
}

// 第九个板块
.s9-box {
  margin-top: 2.7vw;
  width: 100%;
  height: 16.6vw;
  background: linear-gradient(-63deg, #2a375f, #2e3c68);
  border-radius: 0.2vw;
  padding: 1.6vw 0;
  overflow: hidden;
  position: relative;
}

.school-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.school-item {
  height: 100%;
  width: auto;
}

.school-item-box {
  width: 29.2vw;
  height: 13.4vw;
  position: relative;
  transition: all 2s;
}

.school-item-box:hover .school-item-desc {
  cursor: pointer;
  // transform: scale(1.1);
  opacity: 1;
  /*以y轴为中心旋转*/
}

.school-item-desc {
  width: 100%;
  height: 2.9vw;
  line-height: 2.9vw;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(1, 210, 180, 0.9);
  text-align: center;
  font-size: 1.3vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  opacity: 0;
}

.s9-mark-left,
.s9-mark-right {
  width: 5vw;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}

.s9-mark-left {
  background-image: linear-gradient(to left,
      rgba(34, 44, 79, 0) 0%,
      rgba(34, 44, 79, 0.4) 10%,
      rgba(34, 44, 79, 0.6) 40%,
      rgba(34, 44, 79, 0.8) 60%,
      rgba(34, 44, 79, 1) 100%);
}

.s9-mark-right {
  background-image: linear-gradient(to right,
      rgba(34, 44, 79, 0) 0%,
      rgba(34, 44, 79, 0.4) 10%,
      rgba(34, 44, 79, 0.6) 40%,
      rgba(34, 44, 79, 0.8) 60%,
      rgba(34, 44, 79, 1) 100%);
}

.s9-mark-left {
  left: 0;
}

.s9-mark-right {
  right: 0;
}

// 第十个板块
.question-list {
  margin: 2.7vw 0 3.6vw;
  padding: 0 18.8vw;
}

.ql-item-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.9vw;
}

.ql-item-box:last-child {
  margin-bottom: 0;
}

.ql-item {
  padding: 0.9vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30vw;
  height: 3.9vw;
  background: linear-gradient(180deg, #f9fbff, #ffffff);
  border: 0.1vw solid #ffffff;
  box-shadow: 0px 0.5vw 0.9vw 0px rgba(154, 172, 216, 0.08);
  border-radius: 0.2vw;
}

.qlib-left {
  display: flex;
  align-items: center;
  font-size: 0.8vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #8c94aa;
  line-height: 1.3vw;
  margin-left: 0.5vw;
  text-align: left;
}

.quara-green {
  width: 0.5vw;
  height: 0.5vw;
  background: #2affc8;
  margin-right: 1.8vw;
}

.qlib-right {
  width: 6.5vw;
  height: 2.1vw;
  line-height: 2.1vw;
  background: #01d2b4;
  border-radius: 0.2vw;
  font-size: 0.8vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
}

.qlib-right:hover {
  cursor: pointer;
}

.fire {
  width: 0.8vw;
  height: 1.2vw;
  background: url(../../assets/images/test-pc/icon33.png) no-repeat;
  background-size: 100% 100%;
  margin-right: 1.5vw;
}

.color-fire {
  font-size: 0.8vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ff9a4e;
  line-height: 1.3vw;
}

.hot-text {
  width: 2.2vw;
  height: 1.4vw;
  background: url(../../assets/images/test-pc/icon32.png) no-repeat;
  background-size: 2.2vw 1.4vw;
  // border: 1px solid red;
  margin-top: -1vw;
}

.fb-item_active {
  background-color: #ffffff;
}

.s3ib-item_active .hightest-money {
  background: linear-gradient(18deg, #f7cb6b, #fba980) !important;
}
</style>
